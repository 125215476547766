<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkDropdown from "@components/input/AkDropdown";
import AkDateTime from "@components/input/AkDateTime";
import AkCheckboxButton from "@components/input/AkCheckboxButton";
import AkInputText from "@components/input/AkInputText";
import LinkRow from "@views/link/components/LinkRow";
import AkDialog from "@components/general/AkDialog";
import Toast from 'primevue/toast';

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";
import trafficConditionConst from "@mixins/const/trafficConditionConst";
import roadwayStateConst from "@mixins/const/roadwayStateConst";
import snowStateConst from "@mixins/const/snowStateConst";
import eventMixin from "@mixins/eventMixin";
import dateFormatter from "@mixins/dateFormatter";
import linkTypeConst from "@mixins/const/linkTypeConst";
import linkNightConst from "@mixins/const/linkNightConst";
import typeVhConst from "@mixins/const/typeVhConst";

/* SERVICES */
import linkSheetService from "@services/linkSheetService";
import linkRowService from "@services/linkRowService";
import rolePermissionService from "@services/rolePermissionService";

export default {
  components: {
    AkFormView,
    AkFormSubmitted,
    AkDropdown,
    AkDateTime,
    AkCheckboxButton,
    AkInputText,
    LinkRow,
    AkDialog,
    Toast
  },
  mixins: [randomRef, trafficConditionConst, roadwayStateConst, snowStateConst, eventMixin, dateFormatter, roleMixin, linkTypeConst, linkNightConst, typeVhConst],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "link_sheet.update",
    }
  },
  data() {
    return {
      submitted: false,
      validationSubmitted: false,
      current: {},
      linkRows: [],
      events: [],
      copy: {
        data: null,
      },
      canDownload: false,
      canUpdate: false,
      canValidate: false
    }
  },
  validations() {
    return {
      current: {
        date: {required},
        agerId: {required},
        uerId: {required},
        ceiId: {required},
      }
    }
  },
  mounted() {
    this.getRef().showLoader(this.$refs.form);

    let p4 = linkSheetService.findByIdWithRowsForView(this.$route.params.id).then(data => {
      this.current = data.linkSheet;

      if (this.current.archived != null && this.current.archived) {
        this.$router.replace({ path: `/link/${this.$route.params.id}/details`, query: { error: this.$t("impossible_to_update_archived_sheet") } });
      }

      this.linkRows = data.linkRows;
      this.validated = this.current.validated;
      this.canUpdate = data.canUpdate;
      this.isArchived = this.current.archived;
      this.canDownload = data.canDownload;
      this.canValidate = data.canValidate;
    });

    Promise.all([p4]).then(() => {
      this.events = this.getSheetEvents(this.current);
      this.loading = false;
      this.getRef().hideLoader();
    });
  },
  methods: {
    updateSheet() {
      if (!this.checkForm()) {
        this.getRef().error(this.$t("link_sheet.error.update"));
        return;
      }

      this.getRef().showLoader(this.$refs.form)
      let linkSheetDTO = {
        linkSheet: this.current,
        linkRows: this.linkRows,
        validated: this.validationSubmitted
      }
      linkSheetService.update(linkSheetDTO).then(() => {
            this.updateLinkRows();
            this.$refs.dialogValidate.hide();
          }
      ).catch(e => {
            this.getRef().hideLoader();
            this.getRef().error(this.$t("error." + e.response.data.error));
            this.submitted = false;
            this.validationSubmitted = false;
            this.$refs.dialogValidate.hide();
          }
      );
    },
    updateAndValidate() {
      this.validationSubmitted = true;
      this.updateSheet();
    },
    updateDraftSheet() {
      this.validationSubmitted = false;
      this.current.dateValidated = null;
      this.current.status = "DRAFT";
      this.updateSheet();
    },
    updateLinkRows() {
      let promises = [];
      for (const element of this.linkRows) {
        let p = linkRowService.update(element);
        promises.push(p);
        p.catch(e => {
          this.getRef().hideLoader();
          this.getRef().error(this.$t("error." + e.response.data.error));
          this.submitted = false;
          this.validationSubmitted = false;
        });
      }
      Promise.all(promises).then(() => {
        let msg = this.$t(this.validationSubmitted ? "link_sheet.updated_validated" : "link_sheet.updated");
        this.$router.replace({path: `/link/${this.current.id}/details`, query: {msg: msg}});
      });
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error;
    },
    copyRow(index) {
      let row = this.linkRows[index];
      this.copy.data = {
        agentNumber: row.agentNumber,
        airTemp: row.airTemp,
        alertHour: row.alertHour,
        brineLiters: row.brineLiters,
        curative: row.curative,
        drySaltTonnage: row.drySaltTonnage,
        endHour: row.endHour,
        freezingFog: row.freezingFog,
        freezingHumidity: row.freezingHumidity,
        groundFrost: row.groundFrost,
        groundTemp: row.groundTemp,
        humidity: row.humidity,
        obs1: row.obs1,
        obs2: row.obs2,
        obs3: row.obs3,
        patrol: row.patrol,
        precurative: row.precurative,
        rainOnFrozenGround: row.rainOnFrozenGround,
        rainOnSurfusion: row.rainOnSurfusion,
        roadwayState: row.roadwayState,
        scraping: row.scraping,
        singularPoint: row.singularPoint,
        snowState: row.snowState,
        startHour: row.startHour,
        trafficCondition: row.trafficCondition,
      }
      this.$toast.add({severity: 'success', summary: this.$t("form.row_copied"), life: 1000});
    },
    pasteRow(index) {
      if (this.copy.data) {
        let row = this.linkRows[index];
        row.agentNumber = this.copy.data.agentNumber;
        row.airTemp = this.copy.data.airTemp;
        row.alertHour = this.copy.data.alertHour;
        row.brineLiters = this.copy.data.brineLiters;
        row.curative = this.copy.data.curative;
        row.drySaltTonnage = this.copy.data.drySaltTonnage;
        row.endHour = this.copy.data.endHour;
        row.freezingFog = this.copy.data.freezingFog;
        row.freezingHumidity = this.copy.data.freezingHumidity;
        row.groundFrost = this.copy.data.groundFrost;
        row.groundTemp = this.copy.data.groundTemp;
        row.humidity = this.copy.data.humidity;
        row.obs1 = this.copy.data.obs1;
        row.obs2 = this.copy.data.obs2;
        row.obs3 = this.copy.data.obs3;
        row.patrol = this.copy.data.patrol;
        row.precurative = this.copy.data.precurative;
        row.rainOnFrozenGround = this.copy.data.rainOnFrozenGround;
        row.rainOnSurfusion = this.copy.data.rainOnSurfusion;
        row.roadwayState = this.copy.data.roadwayState;
        row.scraping = this.copy.data.scraping;
        row.singularPoint = this.copy.data.singularPoint;
        row.snowState = this.copy.data.snowState;
        row.startHour = this.copy.data.startHour;
        row.trafficCondition = this.copy.data.trafficCondition;
        this.$toast.add({severity: 'success', summary: this.$t("form.row_pasted"), life: 1000});
      } else {
        this.$toast.add({severity: 'error', summary: this.$t("form.no_row_copied"), life: 3000});
      }
    },
    openValidationDialog() {
      if (!this.checkForm()) return;
      this.$refs.dialogValidate.show();
    },
    
    // ALERTE
    
    isAlerte() {
      return this.isAlerte1() || this.isAlerte2();
    },
    buildAlerteMessage() {
      if(this.isAlerte1() && this.isAlerte2()) return this.$t('link_sheet.confirm_validation_alerte1')+' '+this.$t('link_sheet.confirm_validation_alerte2')+' '+this.$t('link_sheet.confirm_validation');
      if(this.isAlerte1()) return this.$t('link_sheet.confirm_validation_alerte1')+' '+this.$t('link_sheet.confirm_validation');
      if(this.isAlerte2()) return this.$t('link_sheet.confirm_validation_alerte2')+' '+this.$t('link_sheet.confirm_validation');
      return '';
    },
    
    // Alerte1 : Vous avez renseigné une température du sol > 0°C et indiqué une stratégie de traitement par du sel.
    
    isAlerte1() {
      let nb1 = this.linkRows.filter(row => row.groundTemp !== null && row.groundTemp > 0);
      let nb2 = this.linkRows.filter(row => row.drySaltTonnage !== null && row.drySaltTonnage > 0);
      return nb1.length > 0 && nb2.length > 0;
    },
    
    // Alerte2 : Vous avez renseigné un tonnage de sel sec supérieur à 30 tonnes ou une quantité de saumures supérieure à 8000 litres.
    
    isAlerte2() {
      let nb1 = this.linkRows.filter(row => row.brineLiters !== null && row.brineLiters > 8000);
      let nb2 = this.linkRows.filter(row => row.drySaltTonnage !== null && row.drySaltTonnage > 30);
      return nb1.length > 0 || nb2.length > 0;
    },
  },
  computed: {
    agerList() {
      return [{id: this.current.agerId, label: this.current.agerLabel}]
    },
    uerList() {
      return [{id: this.current.uerId, label: this.current.uerLabel}]
    },
    ceiList() {
      return [{id: this.current.ceiId, label: this.current.ceiLabel}]
    },
    tomorrow() {
      return this.formatDate(this.addDays(this.current.date, 1));
    },
    canUpdateLink() {
      return this.canUpdate && this.current.id !== undefined;
    },
    canValidateLink() {
      return this.canValidate;
    },
  },
}
</script>

<template v-if=!roleLoading>
  <Toast/>
  <AkFormView :ref="ref" :title="$t('link_sheet.update')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkCheckboxButton v-model="current.night"
                            class-name="col-md-2"
                            :label="$t('day_night')"
                            :multiple="false"
                            :options="linkNightConst"/>
          <AkDateTime v-model="current.date"
                      :validator="v$.current.date"
                      :label="$t('date')"
                      :showTime=false
                      class-name="col-md-2"/>
          <AkInputText v-model="tomorrow"
                       :disabled=true
                       v-if=current.night
                       :label="$t('date_after')"
                       class-name="col-md-2"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('link_sheet.type')"
                      v-model="current.type"
                      :validator="v$.current.type"
                      :submitted=this.submitted
                      :options="linkTypeConst"
                      class-name="col-md-4"/>
        </div>
        <div class="form-row">
          <AkInputText :disabled="true" v-model="current.agerLabel" class-name="col-md-4" :label="$t('ager_label')"/>
          <AkInputText :disabled="true" v-model="current.uerLabel" class-name="col-md-4" :label="$t('uer_label')"/>
          <AkInputText :disabled="true" v-model="current.ceiLabel" class-name="col-md-4" :label="$t('cei_label')"/>
        </div>
        <div class="form-row" v-if="current.typeVh">
          <AkCheckboxButton v-model="current.typeVh"
                            class-name="col-md-6"
                            :label="$t('type_vh_label')"
                            :multiple="false"
                            :options=typeVhConst
                            :validator="v$.current.typeVh"
                            :disabled="true"
                            :submitted="this.submitted"/>
        </div>

        <h5>{{ $t('link_sheet.circuits') }}</h5>

        <div class="mt-2 row">
          <LinkRow v-for="(row, index) in linkRows"
                   :key="index"
                   :row="row"
                   :index="index"
                   ref="panels"
                   :submitted=this.submitted
                   @copyRow="copyRow"
                   @pasteRow="pasteRow"/>
        </div>

        <h5>{{ $t('link_sheet.history') }}</h5>

        <div class="form-row">

          <Timeline :value="events">
            <template #opposite="slotProps">
              <small class="p-text-secondary">{{ slotProps.item.dateDisplay }}</small>
            </template>
            <template #content="slotProps">
              {{ slotProps.item.name }}
            </template>
          </Timeline>

        </div>

        <div v-if=currentUser class="float-right">
          <button v-if=canValidateLink @click=openValidationDialog() class="btn btn-success">
            {{ $t('validate_send') }}
          </button>
          <button v-if=canUpdateLink @click=updateDraftSheet() class="btn btn-primary" style="margin-left: 10px">
            {{ $t('save_draft') }}
          </button>
        </div>

      </AkFormSubmitted>
    </template>

    <template v-slot:extra>
      <AkDialog ref="dialogValidate"
                :cancel-label="$t('no')"
                :title="$t('link_sheet.validate_dialog')"
                :validate-label="$t('yes')"
                width="450px"
                @validate="this.updateAndValidate()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span v-if="!isAlerte()">{{ $t('link_sheet.confirm_validation') }}</span>
          <span style="color: red;" v-if="isAlerte()">{{ buildAlerteMessage() }}</span>
        </div>
      </AkDialog>
    </template>

  </AkFormView>
</template>